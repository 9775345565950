import axios from 'axios';
import { getAccessToken, setAccessToken, getRefreshToken, clearTokens } from '../utils/commonutils';
import { notifyError } from './NotificationService';
// my backend server hosted on this domain 
const BaseUrl = 'https://tlinikah.pythonanywhere.com/';
// const BaseUrl = 'http://127.0.0.1:8000/'; 

const axiosProtectedInstance = axios.create({
    baseURL: BaseUrl,
    headers: {
        "content-type": "application/json"
    }
});


const axiosNormalInstance = axios.create({
    baseURL: BaseUrl,
    headers: {
        "content-type": "application/json"
    }
});

// Request interceptor to add the access token to the headers
// Request interceptor to add the access token to the headers
// if token found hit the api other wise hit without token 
axiosProtectedInstance.interceptors.request.use(
    config => {
        const token = getAccessToken();
        if (token) { 
            config.headers['Authorization'] = `${token}`;
        } else {
            // Throw an error to reject the request
            return Promise.reject(new Error("AcessTokenUndefined"));
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


// Response interceptor to handle token refresh
// if in response we are getting backend server need token the generate it from refresh token 
axiosProtectedInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = getRefreshToken();                
                const response = await axios.post(`${BaseUrl}/api/token/refresh/`, { refresh: refreshToken });
                setAccessToken(response.data.access);
                originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;
                return axiosProtectedInstance(originalRequest);
            } catch (refreshError) {
                // Check if the refresh token itself is invalid or expired
                if (refreshError.response?.data?.code === 'token_not_valid' || refreshError.response?.status === 401) {
                    // Clear tokens and redirect to login
                    clearTokens(); // Implement a function to clear stored tokens
                    notifyError("Session is expired please login");

                    // Wait for 3 seconds before redirecting to the login page
                    setTimeout(() => {
                        window.location.href = '/login'; // Redirect user to login
                    }, 3000); // 3000 milliseconds = 3 seconds
                }

                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);


// Response interceptor to handle token refresh
// i need to test it  
// Response interceptor to handle token refresh
// axiosProtectedInstance.interceptors.response.use(
//     response => response,
//     async error => {
//         const originalRequest = error.config;

//         if (error.response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;
//             console.log(originalRequest, "This is the last request");

//             try {
//                 // Get the refresh token from cookies
//                 const refreshToken = Cookies.get('refresh');
//                 console.log(refreshToken)
//                 if (!refreshToken) {
//                     throw new Error('No refresh token available');
//                 }
//                 const response = await axiosNormalInstance.post('/api/token/refresh/', {}, {
//                     withCredentials: true  // Important to include cookies in the request
//                 });
//                 console.log("This is response middleware", response);

//                 setAccessToken(response.data.access);
//                 originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;
//                 return axiosProtectedInstance(originalRequest);
//             } catch (refreshError) {
//                 console.error('Token refresh failed:', refreshError);
//                 // Handle token refresh failure, e.g., redirect to login page
//                 // window.location.href = '/login'; // Uncomment if you want to redirect to login page
//                 return Promise.reject(refreshError);
//             }
//         }
//         return Promise.reject(error);
//     }
// );

export { axiosProtectedInstance, axiosNormalInstance, BaseUrl };
