import React from "react";
import { Link } from "react-router-dom";
import { MDBFooter} from 'mdb-react-ui-kit';
import {AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import { CiYoutube } from "react-icons/ci";
import { AiFillBank } from "react-icons/ai";

export default function Footer() {
  
  return (
    <>
      <div className="footer">
        <div>
              {/* Your content here */}
        </div>
        <MDBFooter bgColor="light" className="text-center text-lg-start text-muted">
          <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
            <div className="me-5 d-none d-lg-block">
              <span>Get connected with us on social networks:</span>
            </div>

            <div>
              {/* <Link to="/" className="me-4 text-reset"><AiFillGithub /></Link> */}
              <Link to="https://thelogicalislam.org/ " className="me-4 text-reset"><AiFillBank/></Link>
              <Link to="https://www.youtube.com/@TheLogicalIslam" className="me-4 text-reset"><CiYoutube /></Link>
              <Link to="https://www.instagram.com/the.logical.islam/" className="me-4 text-reset"><AiFillInstagram /></Link>
              <Link to="https://facebook.com/thelogicalislam" className="me-4 text-reset"><AiFillFacebook /></Link>
            </div>
          </section>

          {/* <section className="">
            <MDBContainer className="text-center text-md-start mt-5">
              <MDBRow className="mt-3">
                <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold mb-4">
                    <MDBIcon icon="gem" className="me-3" />
                    Tech Used in Portfolio
                  </h6>
                  <p>{home_page_data?.footer?.portfolio_motive}</p>
                </MDBCol>

                <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold mb-4">
                    <MDBIcon icon="gem" className="me-3" />
                    Portfolio features
                  </h6>
                  <p>loign, singup, create acount for article writter, blog post, milestone, all poject
                  listed, tech stack , admin dashboard to manage the portfolio</p>
                </MDBCol>

                <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                  <p><Link to="/" className="text-reset">Home</Link></p>
                  <p><Link to="/projects" className="text-reset">Project</Link></p>
                  <p><Link to="/about" className="text-reset">About</Link></p>
                  <p><Link to="/blog" className="text-reset">Blog</Link></p>
                </MDBCol>

                <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                  <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                  <p><MDBIcon icon="envelope" className="me-3" /> {home_page_data?.footer?.email_id}</p>
                  <p><MDBIcon icon="phone" className="me-3" /> {home_page_data?.footer?.mobile_number_1}</p>
                  <p><MDBIcon icon="print" className="me-3" /> {home_page_data?.footer?.mobile_number_2}</p>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section> */}

          <div className="bg-success text-white text-center py">
            © 2024 TLI Nikah
          </div>
        </MDBFooter>
      </div>
    </>
  );
}
