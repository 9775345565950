import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  is_logged_in: false,
  firstname: "", // Add a field to store the user's firstname
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.is_logged_in = true;
      state.firstname = action.payload; // Save the firstname from the payload
    },
    logout: (state) => {
      state.is_logged_in = false;
      state.firstname = ""; // Clear the firstname on logout
    },
  },
});
export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
