import React, { useEffect } from "react";
import styles from "../../css/home.module.css";
// import TypeWriterEffect from "react-typewriter-effect";
import { useDispatch, useSelector } from 'react-redux';
import { RiAdminFill } from "react-icons/ri";
import { setStateData, UserProfileExistOrNot, allChoicesFromBackend } from "../../features/tliservice/initialService";
import { Link } from 'react-router-dom';
import { HiOutlineLogout } from "react-icons/hi";
import { notifySuccess } from '../../services/NotificationService'; 
import { logout } from '../../features/auth/authSlice'; 
import { clearTokens } from '../../utils/commonutils';
import { getStatesData, getProfileUrl, getProfileChoiceFieldsUrl } from "../../services/ProfileService";
import ProfileCard from "./ProfileCard";
import { CiSearch } from "react-icons/ci";

function Home() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.is_logged_in);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const stateData = await getStatesData();
        const profileData = await getProfileUrl();
        const allChoices = await getProfileChoiceFieldsUrl();
        dispatch(setStateData(stateData.data));
        dispatch(UserProfileExistOrNot(profileData.data));
        dispatch(allChoicesFromBackend(allChoices.data));

      } catch (err) {
        console.error('Loading all initial data', err);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logout());
    clearTokens()
    notifySuccess("Logged out successfully!")
  };

  return (
    <>
      <div className="home">

        {/* the top hero section  */}
        <div className={`${styles.hero}`}>
          <div className="container-fluid">
            <div className={`row d-flex flex-row ${styles.main}`}>
              {/* Left Section: Greeting and TypeWriter Effect */}
              <div className="col-md-6 col-12 d-flex justify-content-center flex-column">
                <div className="col-md-6 mt-2 align-self-center mt-5">
                <ProfileCard
                  imageUrl="/kimam1.png" // Replace with the actual image path
                  altText="Profile Picture"
                />
                </div>
              </div>

              {/* Right Section: Action Buttons */}
              <div className="col-md-6 col-12 d-flex justify-content-center">
                <div className="align-self-center">
                <h4 className="text-center mb-2 fw-bold" style={{ color:"white" }} >
                  Begin Your Blessed Journey Today!
                </h4>
                <p className="text-center mb-2" style={{ fontStyle: "italic", color: "white" }} >
                  {/* Join today and connect with your other half in a halal way, following the Sunnah. */}
                  Join today and find the alliance in a halal way
                </p>


                  <div className="text-center">
                    {isLoggedIn ? (
                      <>
                        <Link
                          className="btn btn-sm btn-outline-primary me-3 text-white"
                          to="/"
                          onClick={handleLogout}
                        >
                          <HiOutlineLogout style={{ marginRight: '3px' }} />
                          Logout
                        </Link>
                        <Link
                          className="btn btn-sm btn-outline-primary text-white"
                          to="/profile"
                        >
                          <RiAdminFill style={{ marginRight: '3px' }} />
                          Profile
                        </Link>

                        <div className="mt-3">
                          <Link
                            className="btn btn-sm btn-outline-primary text-white"
                            to="/searchprofile"
                          >
                            <CiSearch style={{ marginRight: '3px' }} />
                            Search Partner
                          </Link>
                        </div>
                      </>
                    ) : (
                      <>
                        <Link
                          className="btn btn-outline-primary me-3 text-white"
                          to="/login"
                        >
                          Login
                        </Link>
                        <Link
                          className="btn btn-outline-primary text-white"
                          to="/register"
                        >
                          Register
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




          {/* Add new section to highlight features */}
          <div className="container mt-5">
            <div className="text-center mb-5">
              <h3 style={{ color: "#2980b9", fontSize: "2.5em", fontWeight: "600" }}>Why Choose TLI NIKAH?</h3>

            </div>

            <div className="row">
              {/* Secure Feature */}
              <div className="col-md-3 col-sm-6 mb-4">
                <div className="card shadow-lg border-0 rounded p-3 text-center h-100">
                  <div className="card-body">
                    <i className="fa fa-lock fa-4x" style={{ color: "#2980b9" }}></i>
                    <h4 className="card-title mt-3" style={{ color: "#2c3e50", fontWeight: "bold" }}>Secure</h4>
                    <p className="card-text" style={{ color: "#7f8c8d", fontSize: "1.1em" }}>
                      Your privacy and security are our top priority. We ensure that your data is always safe.
                    </p>
                  </div>
                </div>
              </div>

              {/* Faith-driven Feature */}
              <div className="col-md-3 col-sm-6 mb-4">
                <div className="card shadow-lg border-0 rounded p-3 text-center h-100">
                  <div className="card-body">
                    <i className="fa fa-heart fa-4x" style={{ color: "#2980b9" }}></i>
                    <h4 className="card-title mt-3" style={{ color: "#2c3e50", fontWeight: "bold" }}>Faith-driven</h4>
                    <p className="card-text" style={{ color: "#7f8c8d", fontSize: "1.1em" }}>
                      A platform built on Islamic principles. Helping you find a partner that shares your faith.
                    </p>
                  </div>
                </div>
              </div>

              {/* Personalized Feature */}
              <div className="col-md-3 col-sm-6 mb-4">
                <div className="card shadow-lg border-0 rounded p-3 text-center h-100">
                  <div className="card-body">
                    <i className="fa fa-cogs fa-4x" style={{ color: "#2980b9" }}></i>
                    <h4 className="card-title mt-3" style={{ color: "#2c3e50", fontWeight: "bold" }}>Personalized</h4>
                    <p className="card-text" style={{ color: "#7f8c8d", fontSize: "1.1em" }}>
                      Tailored profiles that help you find your perfect match. We understand your needs.
                    </p>
                  </div>
                </div>
              </div>

              {/* Community-focused Feature */}
              <div className="col-md-3 col-sm-6 mb-4">
                <div className="card shadow-lg border-0 rounded p-3 text-center h-100">
                  <div className="card-body">
                    <i className="fa fa-users fa-4x" style={{ color: "#2980b9" }}></i>
                    <h4 className="card-title mt-3" style={{ color: "#2c3e50", fontWeight: "bold" }}>Community-focused</h4>
                    <p className="card-text" style={{ color: "#7f8c8d", fontSize: "1.1em" }}>
                      Be part of a growing, supportive community of Muslims striving for a blessed marriage.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </div>
    </>
  );
}

export default Home;
