import React, { useState } from 'react';
import { registerUserUrl } from '../../services/UserService';
import LoadingSpinner from '../Loader/loader';
import { notifySuccess , notifyError} from '../../services/NotificationService';
import { useNavigate } from 'react-router-dom';

// Define initial SignUpData object
const initialSignUpData = {
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: '',
    password: "",
    password2: "",
    relationship: "",
    commonFriend: ''
};

function SignUp() {
    const [signUpData, setSignUpData] = useState(initialSignUpData);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (event) => {
        setSignUpData({ ...signUpData, [event.target.name]: event.target.value });
        console.log(signUpData)
    };

    const signUpUser = async (event) => {
        try {
            event.preventDefault();
    
            // Validate email format
            if (!signUpData.email || !signUpData.email.includes('@') || !signUpData.email.includes('.')) {
                alert('Please enter a valid email address.');
                return;
            }
    
            // Validate password match
            if (signUpData.password !== signUpData.password2) {
                alert('Passwords do not match. Please confirm your password correctly.');
                return;
            }
    
            // Validate relationship selection
            if (!signUpData.relationship) {
                alert('Please select how you know Khurshid Imam.');
                return;
            }
    
            // If "Common friend" is selected, validate common friend's name
            if (signUpData.relationship === "Common friend" && !signUpData.commonFriend) {
                alert('Please enter the name of the common friend.');
                return;
            }

            if (signUpData.relationship === "Common friend" && !signUpData.contactNumber) {
                alert('Please enter common friend Mobile Number.');
                return;
            }

            if (signUpData.relationship === "Common friend" && signUpData.contactNumber) {
                
                // Check if the contact number contains only digits
                if (!/^\d+$/.test(signUpData.contactNumber)) {
                    alert('The mobile number must contain only digits.');
                    return;
                }


                // Check if the contact number has exactly 10 digits
                if (signUpData.contactNumber.length > 14) {
                    alert('The mobile number must be less than 15 digits.');
                    return;
                }   

            }
    
            // If all validations pass, proceed with API call
            setLoading(true);
            await registerUserUrl(signUpData);
            setLoading(false);
            navigate('/login');
            notifySuccess("Signup successfully!");

    
        } catch (e) {
            setLoading(false);
            notifyError(e.message || 'Please try again later.');
        }
    };
    

    if (loading) {
        return <LoadingSpinner visible={true} />;
    }

    return (
        <>
            <div className="container-fluid vh-100 d-flex justify-content-center align-items-center">
                <div className="row">
                    <div className="col-md-4 col-10 w-100">
                        <form onSubmit={signUpUser} noValidate className="border p-4 rounded bg-light">
                            <h2 className="text-center mb-4">Signup</h2>

                            <div className="mb-4">
                                <label className="form-label">First Name:</label>
                                <input
                                    type="text"
                                    className="form-control border border-primary"
                                    name="firstName"
                                    onChange={handleChange}
                                    placeholder="Enter First Name"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="form-label">Last Name:</label>
                                <input
                                    type="text"
                                    className="form-control border border-primary"
                                    name="lastName"
                                    onChange={handleChange}
                                    placeholder="Enter Last Name"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="form-label">Email:</label>
                                <input
                                    type="email"
                                    className="form-control border border-primary"
                                    name="email"
                                    onChange={handleChange}
                                    placeholder="Enter Your Email"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="form-label">Password:</label>
                                <input
                                    type="password"
                                    className="form-control border border-primary"
                                    name="password"
                                    onChange={handleChange}
                                    placeholder="Please Enter Password"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="form-label">Confirm Password:</label>
                                <input
                                    type="password"
                                    className="form-control border border-primary"
                                    name="password2"
                                    onChange={handleChange}
                                    placeholder="Confirm Your Password"
                                />
                            </div>

                            {/* Dropdown field with arrow styling */}
                            <div className="mb-4">
                                <label className="form-label">How do you know Khurshid Imam?</label>
                                <select
                                    className="form-control custom-select border border-primary"
                                    name="relationship"
                                    value={signUpData.relationship}
                                    onChange={handleChange}
                                    style={{
                                        appearance: 'none',
                                        background: 'url(https://cdn-icons-png.flaticon.com/512/271/271228.png) no-repeat right 10px center',
                                        backgroundColor: 'white',
                                        backgroundSize: '16px',
                                        paddingRight: '30px'
                                    }}
                                >
                                    <option value="">Select an option</option>
                                    <option value="TLI Family/Samparkk">I am from TLI Family / Samparkk</option>
                                    <option value="Deeni circle">I am from khurshid imam's deeni circle</option>
                                    <option value="Relative">I am his relative / acquaintances</option>
                                    <option value="Common friend">I know him through a common friend</option>
                                    <option value="unknown">I do not know brother Khurshid Imam</option>
                                </select>
                            </div>

                            {/* Conditionally render common friend input field */}
                            {signUpData.relationship === "Common friend" && (
                                <>
                                    <div className="mb-4">
                                        <label className="form-label">Common Friend's Name:</label>
                                        <input
                                            type="text"
                                            className="form-control border border-primary"
                                            name="commonFriend"
                                            value={signUpData.commonFriend}
                                            onChange={handleChange}
                                            placeholder="Enter Common Friend's Name"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="form-label">Your Contact Number:</label>
                                        <input
                                            type="tel"
                                            className="form-control border border-primary"
                                            name="contactNumber"
                                            value={signUpData.contactNumber}
                                            onChange={handleChange}
                                            placeholder="Enter Your Number"
                                            maxlength="14" // Restrict to 5 characters
                                        />
                                    </div>
                                </>

                            )}

                            <button className="btn btn-success w-100" type="submit">SignUp</button>

                            <div className="mt-4 d-flex justify-content-between w-100">
                                <a href="/reset-password" className="text-decoration-none">Forgot Password</a>
                                <a href="/login" className="text-decoration-none">Login</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignUp;
