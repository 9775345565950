import React, { useState } from "react";
import { uploadPaymentProof } from "../../services/PaymentService";
import { notifySuccess, notifyError } from "../../services/NotificationService";
import { useNavigate } from "react-router-dom";

const PaymentInstructions = ({ status }) => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Store the selected file in state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      notifyError("Please select a file before submitting.");
      return;
    }

    try {
      await uploadPaymentProof(file); // Call the API function
      notifySuccess("Payment proof uploaded successfully!");
      navigate("/");
    } catch (error) {
      notifyError("Failed to upload payment proof. Please try again.");
    }
  };

  const renderPaymentInstructions = (status) => {
    if (status === "pending") {
      return (
        <>
          <p className="card-text text-muted">You have successfully updated the screenshot, wait for admin approval  </p>
             <p className="card-text text-warning">
            If you uploaded the wrong screenshot, you can upload a new one again.
          </p>
        </>

        
      );
    } else if (status === "paid") {
      return (
        <>
          <p className="card-text text-success">
            Make Google Pay or PhonePe payment to 9916599713 as a subscription fee.
          </p>

        </>
      );
    } else {
      return (
        <p className="card-text text-danger">
          Unable to determine payment status. Please try again or contact support.
        </p>
      );
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="card shadow-lg p-4" style={{ maxWidth: "500px" }}>
        <div className="card-body text-center">
          <h2 className="card-title text-success mb-3">Complete Your Payment</h2>
          {renderPaymentInstructions(status)}
          <p className="card-text mt-3">
            <strong>Amount:</strong>{" "}
            <span className="text-primary fw-bold">₹800</span>
          </p>
          <p className="card-text mt-3">
            <strong>Pay to:</strong>{" "}
            <span className="text-success fw-bold">9916599713</span>
          </p>
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="mb-3">
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="form-control"
                required
              />
            </div>
            <button type="submit" className="btn btn-success w-100">
              Upload Screenshot
            </button>
          </form>
          <div className="mt-3 text-muted small">
            <i className="fas fa-lock me-2"></i>Your information is secure with us.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInstructions;
