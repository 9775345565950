import React from "react";

const Step2 = ({
  formData,
  handleChange,
  handleNext,
  handleBack,
  choices,
  states,
  cities,
  handleStateChange,
}) => {
  // Helper function to render select fields
  const renderSelectField = (label, name, options) => (
    <div className="form-group mb-3">
      <label>{label}</label>
      <select
        className="form-control"
        name={name}
        value={formData[name]}
        onChange={handleChange}
        required
      >
        <option value="">Select {label}</option>
        {options &&
          options.map((option) => (
            <option key={option.id || option.key} value={option.id || option.key}>
              {option.name || option.value}
            </option>
          ))}
      </select>
    </div>
  );

  return (
    <form className="bg-light p-4 rounded shadow" encType="multipart/form-data">
      {renderSelectField("Marital Status", "marital_status", Object.entries(choices.marital_status || {}).map(([key, value]) => ({ key, value })))}
      {renderSelectField("Education", "education", Object.entries(choices.education || {}).map(([key, value]) => ({ key, value })))}
      {renderSelectField("Profession", "profession", Object.entries(choices.profession || {}).map(([key, value]) => ({ key, value })))}

      {/* Monthly Salary Range */}
      <div className="form-group mb-3">
        <label htmlFor="monthly_salary_range">Monthly Salary In Rupees</label>
        <input
          type="number"
          className="form-control"
          name="monthly_salary_range"
          placeholder="monthly slary in number like 20k, 30k etc"
          value={formData.monthly_salary_range}
          onChange={handleChange}
          maxlength="10" // Restrict to 5 characters
          required
        />
      </div>


      {/* State Dropdown */}
      <div className="form-group mb-3">
        <label>State <span style={{ color: "red" }}>*</span> </label>
        <select
          className="form-control"
          name="state"
          value={formData.state}
          onChange={handleStateChange}
          required
        >
          <option value="">Select State</option>
          {states.map((state) => (
            <option key={state.id} value={state.id}>
              {state.name}
            </option>
          ))}
        </select>
      </div>

      {/* City Dropdown */}
      <div className="form-group mb-3">
        <label>City <span style={{ color: "red" }}>*</span> </label>
        <select
          className="form-control"
          name="city"
          value={formData.city}
          onChange={handleChange}
          required
        >
          <option value="">Select City</option>
          {cities.map((city) => (
            <option key={city.id} value={city.id}>
              {city.name}
            </option>
          ))}
        </select>
      </div>

      {/* Navigation Buttons */}
      <div className="d-flex justify-content-between">
        <button type="button" className="btn btn-secondary" onClick={handleBack}>
          Back
        </button>
        <button type="button" className="btn btn-primary" onClick={handleNext}>
          Next
        </button>
      </div>
    </form>
  );
};

export default Step2;
